import React from "react";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Button from "@mui/material/Button";
import { Fade } from "react-awesome-reveal";
export default function About() {
  return (
    <div
      className="about"
      id="about"
      style={{
        display: "flex",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        background: "url(../../assets/blob3.svg) no-repeat",
        backgroundPosition: "center center",
        color: "#0d2f3f",
        backgroundSize: "cover",
      }}
    >
      <main
        style={{
          width: "7rem",
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
        }}
      >
        <Fade direction="down" duration="2000" triggerOnce>
          <div>
            <h1 style={{ textAlign: "center", fontWeight: "400" }}>About Me</h1>
            <p style={{ textAlign: "center", color: "grey" }}>
              My introduction
            </p>
          </div>
        </Fade>
        <div
          className="myInfo"
          style={{
            display: "flex",
            gap: "0.6rem",
            alignItems: "center",
          }}
        >
          <Fade direction="left" duration="2000" triggerOnce>
            <div className="img">
              <img src="../../assets/sixun3.jpg" alt="amazing web developer" />
            </div>
          </Fade>
          <Fade direction="right" duration="2000" triggerOnce>
            <div
              className="text"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.2rem",
                alignItems: "start",
              }}
            >
              <p
                style={{
                  lineHeight: "0.3rem",
                  display: "flex",
                  flexDirection: "column",
                  gap: ".3rem",
                }}
              >
                <p>
                  🧪 Full-stack developer with science master background at the
                  University of Copenhagen
                </p>
                <p>
                  👩‍💻 Switching to do the cool job I always wanted to do. As it
                  turns out, I'm pretty good at it, too.
                </p>
                <p>
                  🖐 From lab to web, I am totally a practical person who always
                  likes to make projects and get my hands dirty
                </p>
              </p>
              <a href="../../assets/Sixun Yang-CV.pdf" download>
                <Button
                  variant="contained"
                  color="success"
                  style={{ fontSize: ".12rem", width: "1.6rem" }}
                  endIcon={<UploadFileIcon />}
                >
                  Download CV
                </Button>
              </a>
            </div>
          </Fade>
        </div>
      </main>
    </div>
  );
}
