import React, { useState } from "react";
import SkillHeader from "../components/SkillHeader";
import SkillInfo from "../components/SkillInfo";
import { frontendInfo, backendInfo, planInfo } from "../data";
import { Fade } from "react-awesome-reveal";
import "./Skills.css";
export default function Skills() {
  const [skillType, setSkillType] = useState(frontendInfo);

  return (
    <div className="skills" id="skills">
      <main>
        <Fade cascade direction="left" triggerOnce>
          <h1 style={{ textAlign: "center", fontWeight: "400" }}>Skills</h1>
          <div className="skillset">
            <div className="skillset-header">
              <SkillHeader
                skillId="101"
                skillName="Front-end"
                skillPic="bi bi-display"
                setSkillType={setSkillType}
                active={skillType === frontendInfo ? true : false}
              />
              <SkillHeader
                skillId="102"
                skillName="Back-end"
                skillPic="bi bi-database-fill-gear"
                setSkillType={setSkillType}
                active={skillType === backendInfo ? true : false}
              />
              <SkillHeader
                skillId="103"
                skillName="Next target"
                skillPic="bi bi-check2-square"
                setSkillType={setSkillType}
                active={skillType === planInfo ? true : false}
              />
            </div>
            <div className="skillset-bottom">
              {skillType.map((ele) => (
                <SkillInfo
                  key={ele.skillId}
                  skillTitle={ele.name}
                  skillContent={ele.content}
                />
              ))}
            </div>
          </div>
        </Fade>
      </main>
    </div>
  );
}
