import React, { useState } from "react";
import { frontendInfo, backendInfo, planInfo } from "../data";
export default function SkillHeader({
  skillId,
  skillName,
  skillPic,
  setSkillType,
  active,
}) {
  /* "#d58a00" */

  const handleClick = () => {
    if (skillId === "101") {
      setSkillType(frontendInfo);
      /*  setactive("#d58a00"); */
    } else if (skillId === "102") {
      setSkillType(backendInfo);
      /* setactive("#d58a00"); */
    } else if (skillId === "103") {
      setSkillType(planInfo);
      /* setactive("#d58a00"); */
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "0.05rem",
        cursor: "pointer",
      }}
      onClick={handleClick}
    >
      <p
        style={{
          color: active ? "green" : "black",
          fontWeight: active ? "800" : "400",
        }}
      >
        {skillName}
      </p>
      <div
        style={{
          width: ".75rem",
          height: ".75rem",
          border: "0.02rem solid ",

          borderColor: active ? "green" : "black",
          borderRadius: ".75rem",
          textAlign: "center",
          lineHeight: ".75rem",
        }}
      >
        <i
          className={skillPic}
          style={{ fontSize: ".4rem", color: active ? "green" : "black" }}
        ></i>
      </div>
    </div>
  );
}
