import React from "react";
import Button from "@mui/material/Button";
import "./ProjectItem.css";
import { Fade } from "react-awesome-reveal";
import Highlighter from "react-highlight-words";
export default function ProjectItem({
  firstProject,
  projectName,
  projectContent,
  projectHref,
  projectImage,
}) {
  return (
    <div className="projectItem">
      {firstProject ? (
        <Fade direction="down" duration="2000" triggerOnce>
          <h1
            style={{
              textAlign: "center",
              fontWeight: "400",
            }}
          >
            Projects
          </h1>
        </Fade>
      ) : null}
      <main>
        <div className="projectInfo">
          <Fade direction="left" duration="2000" triggerOnce>
            <h2 style={{ color: "green" }}>{projectName}</h2>
            <p style={{ lineHeight: ".25rem" }}>
              <Highlighter
                highlightClassName="YourHighlightClass"
                searchWords={["React.js", "React router", "Material UI"]}
                autoEscape={true}
                textToHighlight={projectContent}
              />
            </p>
            <Button
              variant="contained"
              color="success"
              style={{ fontSize: ".12rem", width: "1rem" }}
            >
              <a href={projectHref} target="_blank">
                More Info
              </a>
            </Button>
          </Fade>
        </div>
        <div className="projectVideo">
          <Fade direction="right" duration="2000" triggerOnce>
            <img src={projectImage} />
          </Fade>
        </div>
      </main>
    </div>
  );
}
