import React from "react";
import { AttentionSeeker } from "react-awesome-reveal";
export default function Footer() {
  return (
    <div
      id="gift"
      style={{
        height: "50vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <main
        style={{
          width: "80%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div className="left">
          <AttentionSeeker effect="swing">
            <h1>Let's make something </h1>
            <h1>amazing together.🥳</h1>
          </AttentionSeeker>
        </div>
        <div className="right">
          <ul
            style={{ display: "flex", flexDirection: "column", gap: ".2rem" }}
          >
            <li
              style={{ fontSize: "larger", color: "green", fontWeight: "600" }}
            >
              Contact
            </li>
            <li>2300 Copenhagen S</li>
            <li>+45 71 56 49 24</li>

            <li>Email:yangsixun7@163.com</li>
          </ul>
        </div>
      </main>
    </div>
  );
}
