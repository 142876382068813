export const frontendInfo = [
  {
    skillId: 101,
    name: "HTML/CSS/Javascript",
    content:
      "Beautiful layouts can be built very efficiently by HTML5 and CSS3. In-depth understanding of complex principles and concepts in Javascript(ES6), such as prototype chain, async function, primitive and reference type.",
  },
  {
    skillId: 102,
    name: "React",
    content:
      "Reusable components can be created by react framework, familiar with JSX, virtual dom, react hooks, react router, SPA, axios, RESTful API",
  },
  {
    skillId: 103,
    name: "Material UI/Ant Design",
    content:
      "Skilled in using UI component libraries, improving the speed, consistency, and quality of UI development,easier and more efficient to build web and mobile applications",
  },
];
export const backendInfo = [
  {
    skillId: 104,
    name: "Node.js",
    content:
      "An environment for backend where javascript can also run. Proficiency in installing various packages and reading documents",
  },
  {
    skillId: 105,
    name: "Express",
    content:
      "A package simplifies the HTTP utility methods, handling multiple routes and different types of requests.",
  },
  {
    skillId: 106,
    name: "MySQL",
    content:
      "Popular database management system used for connecting to a MySQL server using Javascript and execute SQL statements to perform operations such as creating tables, inserting data, updating data, and retrieving data.",
  },
];
export const planInfo = [
  {
    skillId: 107,
    name: "Redux",
    content: "",
  },
  {
    skillId: 108,
    name: "AWS Lambda",
    content: "",
  },
  {
    skillId: 109,
    name: "Next.js",
    content: "",
  },
];
export const projects = [
  {
    projectName: "Admin System",
    projectContent:
      "This system was designed to provide administrators with an efficient and user-friendly interface for managing a website or application using React.js, React router, Material UI, Nivo Charts, FullCalendar, and Data Grid. This application consists of 4 different Charts, 3 different Data Table Pages, Form Page, and Calendar Integration.",
    projectHref: "https://github.com/Sixunyang/Dashboard",
    projectImage: "../../assets/admin2-pic_macbookpro13_front.png",
  },
  {
    projectName: "ReDI final project",
    projectContent:
      "To show my gratitude for ReDI school-the best tech school, I designed and built this responsive platform for all ReDI graduates to share their final projects, built with vanilla Javascript on the front-end and Node.js/Express on the back-end.",
    projectHref: "https://github.com/Sixunyang/ReDI-final-project",
    projectImage1: "../../assets/REDI2.png",
    projectImage2: "../../assets/Redi1.png",
  },
  {
    projectName: "Coffee commercial website",
    projectContent:
      "Fully responsive website was designed for a commercial coffee homepage, and built with HTML5/CSS3/Javascript. If you like coffee, don't miss it",
    projectHref: "https://github.com/Sixunyang/Coffee-Website-Homepage",
    projectImage2: "../assets/coffee1.png",
    projectImage1: "../assets/coffeeiphone.png",
  },
];
