import React from "react";
import NavBar from "../components/NavBar";
import { Fade, Rotate } from "react-awesome-reveal";
import "./Home.css";
export default function Home() {
  return (
    <div className="home" id="home">
      <NavBar />

      <main>
        <div className="leftInfo">
          <div>
            <p style={{ fontSize: ".48rem", fontWeight: "600" }}>Hey There,</p>
            <Rotate>
              <p
                style={{
                  fontSize: ".48rem",
                  fontWeight: "600",
                  color: "green",
                }}
              >
                I'm Sixun.
              </p>
            </Rotate>
          </div>

          <ul style={{ display: "flex", gap: ".3rem", fontSize: ".25rem" }}>
            <Fade direction="left" duration="2000">
              <li>
                <a href="https://github.com/Sixunyang" target="_blank">
                  <i class="bi bi-github"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/in/sixun-yang"
                  target="_blank"
                >
                  <i class="bi bi-linkedin"></i>
                </a>
              </li>
            </Fade>
          </ul>
        </div>

        <div style={{ display: "flex", alignItems: "end" }}>
          <Fade duration="2000">
            <div
              style={{
                background: "url(../../assets/splash.png) no-repeat",
                backgroundPosition: "center center",

                backgroundSize: "100% 100%",
                width: "4.5rem",

                display: "flex",
                justifyContent: "center ",
              }}
            >
              <img
                src="../../assets/Sixun_0409-.png"
                alt="my pic"
                style={{
                  height: "4.3rem",
                }}
              />
            </div>
          </Fade>
        </div>

        <div className="rightInfo">
          <Fade direction="right" duration="2000">
            <div>
              <p
                style={{
                  fontWeight: "600",
                  lineHeight: ".5rem",
                  fontSize: ".21rem",
                }}
              >
                I'm passionate full-stack
              </p>
              <p
                style={{
                  fontWeight: "600",
                  lineHeight: ".5rem",
                  fontSize: ".21rem",
                }}
              >
                developer based in Copenhagen
              </p>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: ".2rem",
              }}
            >
              <i className="bi bi-mouse2-fill"></i> Scroll down
              <i className="bi bi-arrow-down scroll"></i>
            </div>
          </Fade>
        </div>
      </main>
    </div>
  );
}
