import React from "react";
import { Fade } from "react-awesome-reveal";
export default function SkillInfo({ skillTitle, skillContent }) {
  const layout = skillContent === "" ? "center" : "flex-start";
  return (
    <div
      className="skillItem"
      style={{
        width: "2.9rem",
        height: "2.5rem",
        border: "0.01rem solid black",
        borderRadius: ".1rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: ".4rem .3rem",
        gap: ".2rem",
        justifyContent: layout,
      }}
    >
      <Fade>
        <h2 style={{ color: "green" }}>{skillTitle}</h2>
        <p style={{ textAlign: "center" }}>{skillContent}</p>
      </Fade>
    </div>
  );
}
