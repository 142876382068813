import React from "react";
import ProjectIphoneItem from "../components/ProjectIphoneItem";
import ProjectItem from "../components/ProjectItem";
import { projects } from "../data";

export default function Project() {
  return (
    <div id="projects">
      <ProjectItem
        firstProject={true}
        projectName={projects[0].projectName}
        projectContent={projects[0].projectContent}
        projectHref={projects[0].projectHref}
        projectImage={projects[0].projectImage}
      />
      <ProjectIphoneItem
        projectName={projects[1].projectName}
        projectContent={projects[1].projectContent}
        projectHref={projects[1].projectHref}
        projectImage1={projects[1].projectImage1}
        projectImage2={projects[1].projectImage2}
      />
      <ProjectIphoneItem
        projectName={projects[2].projectName}
        projectContent={projects[2].projectContent}
        projectHref={projects[2].projectHref}
        projectImage1={projects[2].projectImage1}
        projectImage2={projects[2].projectImage2}
      />
    </div>
  );
}
