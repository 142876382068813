import React from "react";
import Button from "@mui/material/Button";
import "./ProjectItem.css";
import { Fade } from "react-awesome-reveal";
import Highlighter from "react-highlight-words";

export default function ProjectIphoneItem({
  projectName,
  projectContent,
  projectHref,
  projectImage1,
  projectImage2,
}) {
  return (
    <div className="projectItem">
      <main>
        <div className="projectInfo">
          <Fade direction="left" duration="2000" triggerOnce>
            <h2 style={{ color: "green" }}>{projectName}</h2>
            <p style={{ lineHeight: ".25rem" }}>
              <Highlighter
                highlightClassName="YourHighlightClass"
                searchWords={[
                  "vanilla Javascript",
                  "Node.js",
                  "Express",
                  "HTML5",
                  "CSS3",
                  "Javascript",
                ]}
                autoEscape={true}
                textToHighlight={projectContent}
              />
            </p>
            <Button
              variant="contained"
              color="success"
              style={{ fontSize: ".12rem", width: "1rem" }}
            >
              <a href={projectHref} target="_blank">
                More Info
              </a>
            </Button>
          </Fade>
        </div>
        <div className="projectVideo2">
          <Fade direction="right" duration="2000" triggerOnce>
            <img src={projectImage1} className="iphone" />

            <img src={projectImage2} className="mac" />
          </Fade>
        </div>
      </main>
    </div>
  );
}
