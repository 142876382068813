import React, { useState } from "react";

import "./NavBar.css";
export default function NavBar() {
  const [right, setright] = useState("-3rem");

  const clickBurger = () => {
    if (right === "-3rem") {
      setright("0rem");
    } else {
      setright("-3rem");
    }
  };
  return (
    <div className="nav">
      <div className="icon">Sixun</div>
      <ul style={{ right: right }}>
        <div class="close" style={{ cursor: "pointer" }} onClick={clickBurger}>
          <i class="bi bi-x-circle"></i>
        </div>
        <li>
          <a href="#home">Home</a>
        </li>
        <li>
          <a href="#about">About</a>
        </li>
        <li>
          <a href="#skills">Skills</a>
        </li>
        <li>
          <a href="#projects">Projects</a>
        </li>
        <li className="gift">
          <a href="#gift">
            <i class="bi bi-gift" style={{ color: "green" }}></i>
          </a>
        </li>
      </ul>
      <div
        className={right === "-3rem" ? "burger" : "burgerClose"}
        onClick={clickBurger}
      >
        <i class="bi bi-list"></i>
      </div>
    </div>
  );
}
